import React, { useState } from 'react';
import Navbar from '../components/navbar/navbar2';
import Footer from '../components/footer';

import headerImage from '../assets/images/tools/tools-bg.png';
import nandita from '../assets/images/influencer/nandita.png';

const EngRateIg = () => {
    const [username, setUsername] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [userData, setUserData] = useState({
        followers: '78.5k',
        following: '567',
        posts: '90',
        engagementRate: '0.97%',
        averageLikes: '121.1k',
        averageComments: '1.3k'
    });

    const handleInputChange = (event) => {
        setUsername(event.target.value);
    };

    const handleCheckClick = () => {
        // For now, we'll just show the result. 
        // Later, you can add an API call here to fetch actual data based on the username.
        setShowResults(true);
    };

    return (
        <>
            <Navbar />
            <div className='py-8'></div>
            <div id="home" className="h-screen bg-cover relative" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: `center` }}>
                <div className='h-full flex justify-center items-center'>
                    <div className="mx-8 lg:mx-80 text-[#080209] -mt-16">
                        <h1 className=" lg:text-center text-3xl lg:text-4xl font-bold font-poppins my-8 ">Kalkulator Engagement Rate Instagram</h1>
                        <p className="text-base lg:text-xl lg:text-center font-montserrat">Morbi lacinia ultricies sapien vitae condimentum. Mauris dictum massa nec sem tristique pharetra. Nullam mollis fringilla metus eget congue.</p>

                        <div className="flex justify-center mt-8">
                            <div className="relative w-full max-w-lg border-2 border-black rounded-full  bg-white">
                                <input
                                    type="text"
                                    value={username}
                                    onChange={handleInputChange}
                                    placeholder="Masukkan @username"
                                    className="w-full rounded-l-md focus:outline-none bg-transparent font-poppins px-2 py-3"
                                />
                                <button
                                    type="button"
                                    onClick={handleCheckClick}
                                    className="absolute lg:right-3 right-1 top-1/2 transform -translate-y-1/2 px-8 py-2 bg-gradient-to-b from-[#9900EF] to-[#0693E3] text-white font-semibold rounded-full"
                                >
                                    Check
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showResults && (
                <div id='result' className='relative w-full py-12'>
                    <div className='lg:mx-36 mx-8'>
                        <div className='flex lg:flex-row flex-col justify-between items-center shadow-2xl rounded-3xl lg:pl-16'>
                            <div>
                                <img src={nandita} alt="Influencer" />
                            </div>
                            <div className='font-poppins lg:block flex flex-col'>
                                <p className='font-bold text-xl'>@{username}</p>
                                <div className='flex justify-center items-center gap-8 my-4'>
                                    <div className='flex flex-col items-center'>
                                        <p className='font-medium'>{userData.followers}</p>
                                        <p className='text-[#08020999] font-semibold'>Followers</p>
                                    </div>
                                    <div className='flex flex-col items-center'>
                                        <p className='font-medium'>{userData.following}</p>
                                        <p className='text-[#08020999] font-semibold'>Following</p>
                                    </div>
                                    <div className='flex flex-col items-center'>
                                        <p className='font-medium'>{userData.posts}</p>
                                        <p className='text-[#08020999] font-semibold'>Post</p>
                                    </div>
                                </div>
                                <button className='bg-gradient-to-r from-[#0693E3] to-[#9900EF] rounded-full px-4 py-1 hidden lg:block'>
                                    <p className='font-poppins text-white text-sm'>Temukan influencer lain</p>
                                </button>
                            </div>
                            <div className='flex lg:flex-row flex-col justify-evenly bg-[#9859FF] lg:py-32 py-12 lg:rounded-tr-3xl lg:rounded-br-3xl lg:rounded-b-none rounded-b-3xl lg:px-16 px-20 gap-8 w-full lg:w-auto'>
                                <div className='font-poppins text-white flex flex-col items-center'>
                                    <p className='text-4xl font-bold'>{userData.engagementRate}</p>
                                    <p>Engagement rate</p>
                                </div>
                                <div className='font-poppins text-white flex flex-col items-center'>
                                    <p className='text-4xl font-bold'>{userData.averageLikes}</p>
                                    <p>Average likes</p>
                                </div>
                                <div className='font-poppins text-white flex flex-col items-center'>
                                    <p className='text-4xl font-bold'>{userData.averageComments}</p>
                                    <p>Average comment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
}

export default EngRateIg;
