import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar/navbar2';
import TestimonialProfileSlider from '../components/home/testimonial-profile';
import Footer from '../components/footer';


import joinImg from '../assets/images/profile/join-komunitas.png';
import starLogo from '../assets/images/profile/star.png';


import item1 from '../assets/images/profile/item/1.png';
import item2 from '../assets/images/profile/item/2.png';
import item3 from '../assets/images/profile/item/3.png';
import item4 from '../assets/images/profile/item/4.png';
import item5 from '../assets/images/profile/item/5.png';
import item6 from '../assets/images/profile/item/6.png';
import item7 from '../assets/images/profile/item/7.png';
import item8 from '../assets/images/profile/item/8.png';
import item9 from '../assets/images/profile/item/9.png';
import item10 from '../assets/images/profile/item/10.png';
import item11 from '../assets/images/profile/item/11.png';
import item12 from '../assets/images/profile/item/12.png';
import item13 from '../assets/images/profile/item/image 25.png';
import item14 from '../assets/images/profile/item/image 26.png';

const Profile = () => {

    return (
        <>
            < Navbar />
            <div id='header' className='relative w-full pt-12 bg-[#A106F8] text-white min-h-screen'>
                <div className='h-screen'>
                    <div className='lg:mx-36 mx-8 flex lg:flex-row flex-col lg:justify-between justify-center items-center h-full lg:gap-24 gap-8'>
                        <div>
                            <h1 className='lg:text-[4rem] md:text-4xl text-3xl text-left font-poppins font-bold lg:leading-[5rem]'>Komunitas & Support</h1>
                        </div>
                        <div>
                            <p className='font-montserrat lg:text-xl text-base mb-8'>consectetur nec fermentum quis, scelerisque nec urna. Aenean iaculis nisl vitae aliquet finibus. Suspendisse potenti. Pellentesque ut scelerisque lectus. Quisque bibendum odio nibh, ac lobortis risus cursus a. Morbi porttitor mi diam, in rutrum mi vulputate in.</p>
                            <p className='font-montserrat lg:text-xl text-base mb-8'>ada pertanyaan? fermentum quis, scelerisque nec urna. Aenean iaculis nisl vitae aliquet finibus. Suspendisse potenti. Pellentesque ut scelerisque lectus. Quisque bibendum odio nibh, ac lobortis risus cursus a. Morbi porttitor mi diam, in rutrum mi vulputate in.</p>
                            <a className='font-montserrat lg:text-xl text-base font-bold text-[#829dff]'>no costumer support</a>
                        </div>
                    </div>
                </div>
                <div className='lg:mx-36 mx-8 flex justify-around items-center h-full lg:gap-56'>
                    <p className='font-montserrat lg:text-xl text-base mb-8'>Join Komunitas <br />Influencer Kita</p>
                    <div className='lg:h-44 h-32'>
                        <img src={joinImg} className='h-full w-full object-cover' />
                    </div>
                </div>

                <div className='grid lg:grid-cols-3 grid-cols-2 gap-0'>
                    <img src={item1} className='h-full w-full object-cover' />
                    <img src={item2} className='h-full w-full object-cover' />
                    <img src={item3} className='h-full w-full object-cover' />
                    <img src={item4} className='h-full w-full object-cover' />
                    <img src={item5} className='h-full w-full object-cover' />
                    <img src={item6} className='h-full w-full object-cover' />
                    <img src={item7} className='h-full w-full object-cover' />
                    <img src={item8} className='h-full w-full object-cover' />
                    <img src={item9} className='h-full w-full object-cover' />
                    <img src={item10} className='h-full w-full object-cover' />
                    <img src={item11} className='h-full w-full object-cover' />
                    <img src={item12} className='h-full w-full object-cover' />
                </div>

                <div className='flex w-[100%] justify-center'>
                    <div className='w-full'>
                        <img src={item13} className='h-full w-full object-cover' />
                    </div>
                    <div className='w-[75%]'>
                        <img src={item14} className='h-full w-full object-cover' />
                    </div>

                </div>
            </div>


            <div id='testimoni' className='relative w-full pt-12 mb-16 md:mt-0 bg-white'>
                <div className='lg:mx-36 mx-8'>
                    <TestimonialProfileSlider />
                    <br />
                </div>

                <div id='other-testimoni' className='lg:mx-36 mx-8'>
                    <h2 className='text-2xl text-center font-poppins font-bold leading-[5rem] mb-10'>Testimoni lainnya</h2>
                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-12'>

                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='other-testimoni-box' className='flex flex-col shadow-lg rounded border border-gray-100'>
                            <p className='font-montserrat text-base px-5 py-4'>Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik.</p>
                            <div className='bg-[#F6F6F6] px-5 py-3'>
                                <p className='font-montserrat text-base font-medium'>Bunga Rahayu</p>
                                <div className='flex items-center justify-between'>
                                    <p className='font-montserrat text-sm'>DKI Jakarta</p>
                                    <div className='flex items-center'>
                                        <div>
                                            <img src={starLogo} />
                                        </div>
                                        <p className='font-montserrat text-base ml-1'>4.7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer />


        </>
    )
};


export default Profile;