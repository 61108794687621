import React from 'react';
import { Link } from 'react-router-dom';

import headerImage from '../assets/images/header.png'
import aboutImage from '../assets/images/about.png'

import mailLogo from '../assets/images/contact/mail-line.png'
import mapLogo from '../assets/images/contact/map-pin-line.png'
import waLogo from '../assets/images/contact/whatsapp-line.png'
import igLogo from '../assets/images/contact/instagram-line.png'
import ytLogo from '../assets/images/contact/youtube-line.png'
import linkLogo from '../assets/images/contact/linkedin-box-line.png'
import tiktokLogo from '../assets/images/contact/tiktok-line-icon 1.png'
import influencerLeft from '../assets/images/influencer1.png'
import influencerRight from '../assets/images/influencer2.png'
import influencerExample from '../assets/images/influencer-example.png'
import instagramOutline from '../assets/images/logo/instagram_outline.png'

import Navbar from '../components/navbar/navbar';
// import Navbar2 from '../components/navbar/navbar2';
import Cta from '../components/home/cta';
import Partner from '../components/home/partner';
import Footer from '../components/footer';
import TestimonialSlider from '../components/home/testimonial';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    once: true,
    disable: 'phone',
});

const Home = () => {
    return (
        <>
            <Navbar />

            <div id="home" className="h-screen bg-cover relative" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: `center` }}>
                <div className='bg-gradient-to-tr from-[#CD5AE0bb] via-[#A478C788] to-[#446DD4bb] h-full flex justify-center items-center'>
                    <div className="mx-8 lg:mx-80 text-white mt-4">
                        <h1 className=" lg:text-center text-3xl lg:text-5xl font-bold font-poppins my-8 ">Platform <br className='md:hidden' />Influencer <br /> Marketing di Indonesia</h1>
                        <p className="text-base lg:text-xl lg:text-center font-montserrat">Selamat datang di Youth Media Agency, pusat inspirasi dan informasi yang bertekad untuk memberdayakan generasi muda! Kami adalah agen media yang berkomitmen untuk menciptakan pengalaman pemasaran yang menyegarkan dan bermakna bagi pemuda di seluruh dunia.</p>
                        <div className='text-white font-semibold font-montserrat flex justify-center lg:mt-16 mt-4'>
                            <Link to='/' className='border border-white lg:px-12 px-6 py-2 rounded-full'>Temukan Influencer Anda</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Cta />

            <div id='about' className='relative w-full py-12 mt-72 md:mt-0'>
                <div className='lg:mx-36 mx-8'>
                    <h2 className='text-2xl lg:text-3xl text-center font-poppins font-bold mt-12 lg:mb-24 mb-4'>Tentang Youtz Media</h2>
                    <div className='lg:flex justify-between'>
                        <div className='lg:hidden mb-8'>
                            <div>
                                <img src={aboutImage} className='w-full' />
                            </div>
                        </div>
                        <div className='lg:w-[50%] pr-8'>
                            <h3 className='text-2xl font-poppins font-semibold mb-4'>Apa itu <span className='text-[#7A5BD2]'>Youtz Media?</span></h3>
                            <p className='font-montserrat lg:text-xl text-base'>Selamat datang di Youth Media Agency, pusat inspirasi dan informasi yang bertekad untuk memberdayakan generasi muda! Kami adalah agen media yang berkomitmen untuk menciptakan pengalaman pemasaran yang menyegarkan dan bermakna bagi pemuda di seluruh dunia.</p>
                        </div>
                        <div className='lg:block lg:w-[50%] hidden'>
                            <div data-aos="fade-left" data-aos-duration="1000">
                                <img src={aboutImage} className='w-full' />
                            </div>
                        </div>
                    </div>
                </div>
                <svg className='absolute -bottom-8 -z-10' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#BF94D3" fill-opacity="1" d="M0,288L40,272C80,256,160,224,240,208C320,192,400,192,480,181.3C560,171,640,149,720,128C800,107,880,85,960,106.7C1040,128,1120,192,1200,186.7C1280,181,1360,107,1400,69.3L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
            </div>

            <div id='why' className='min-h-screen bg-[#BF94D3] text-white'>
                <div className='lg:mx-36 mx-8 py-12'>
                    <h2 className='font-poppins text-xl lg:text-3xl text-center font-bold lg:pb-8'>Kenapa Youtz Media?</h2>
                    <div className='flex flex-col items-center lg:mx-48 py-8'>
                        <div className='font-poppins lg:text-xl text-base md:text-lg'>
                            <p><i>Youtz Media merupakan sebuah perusahaan media dan jasa kreatif yang bernaung di bawah  PT. Kreasi Potensi Indonesia,</i> berdasarkan akta dan SK pendiriannya, secara umum bergerak  pada penyediaan jasa media dan manajemen informasi serta berita, konsepsi, rancangan,  sampai tahap eksekusi dan penyelenggaraan event. Youtz Media sendiri berfokus pada  beberapa layanan dan jasa :</p>
                        </div>
                        <div className='grid gap-6 lg:grid-cols-3 items-stretch mt-8'>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="0"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>Event Organizer</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-6 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#5E73BF] font-bold'>1</p>
                                </div>
                            </div>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="100"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>News Management</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-6 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#D1D886] font-bold'>2</p>
                                </div>
                            </div>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="200"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>Social media management</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-6 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#5E73BF] font-bold'>3</p>
                                </div>
                            </div>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="300"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>KOL Management</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-5 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#D1D886] font-bold'>4</p>
                                </div>
                            </div>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="400"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>Community Hub Management</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-5 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#5E73BF] font-bold'>5</p>
                                </div>
                            </div>
                            <div className='bg-slate-50 rounded-2xl text-black font-poppins px-6 py-4 relative' data-aos="fade-up" data-aos-delay="500"
                                data-aos-duration="700">
                                <h3 className='font-bold text-center lg:mb-8 mb-4'>Content Production</h3>
                                <p className='text-base lg:text-sm'>Youtz Media menyediakan jasa  perencanaan, konsepsi, sampai  proses eksekusi, dan marketing  even</p>
                                <div className='bg-white py-3 px-5 rounded-full absolute -top-4 -left-4 shadow'>
                                    <p className='text-3xl text-[#D1D886] font-bold'>6</p>
                                </div>
                            </div>
                        </div>
                        <button className="bg-white text-black shadow-lg px-4 py-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl font-poppins font-semibold w-[18rem] mt-8">
                            Lihat selengkapnya
                        </button>

                    </div>

                </div>
            </div>
            <div id='influencer' className='min-h-screen bg-[#F4F8FB'>
                <svg className='absolute -z-10' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#BF94D3" fill-opacity="1" d="M0,32L48,58.7C96,85,192,139,288,138.7C384,139,480,85,576,96C672,107,768,181,864,218.7C960,256,1056,256,1152,218.7C1248,181,1344,107,1392,69.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                <div className='lg:mx-36 mx-8 pb-12 flex flex-col items-center'>
                    <h2 className='font-poppins text-3xl text-center font-bold py-4 lg:text-white'>Youtz Star</h2>
                    <p className='font-poppins text-center lg:text-white'>Favorite Influencer</p>
                    <div className='grid lg:grid-cols-2 lg:gap-32 align-middle w-full lg:px-32'>
                        <div>
                            <div className='flex flex-col items-center py-8'>
                                <img src={influencerLeft} data-aos='fade-right' data-aos-delay='0' data-aos-duration='750' />
                                <p className='font-poppins text-center py-4'  data-aos='fade-right' data-aos-delay='0' data-aos-duration='750'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet neque non nibh porta venenatis.</p>
                                <button className="bg-white text-black shadow-lg px-4 py-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl font-poppins font-bold w-[12rem]"  data-aos='fade-right' data-aos-delay='0' data-aos-duration='750'>
                                    Detail
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-col items-center py-8'>
                                <img src={influencerRight} data-aos='fade-left' data-aos-delay='0' data-aos-duration='750' />
                                <p className='font-poppins text-center py-4'  data-aos='fade-left' data-aos-delay='0' data-aos-duration='750'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet neque non nibh porta venenatis.</p>
                                <button className="bg-gradient-to-br from-[#7D80E0] to-[#C885DE] text-white shadow-lg px-4 py-2 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl font-poppins font-bold w-[12rem]"  data-aos='fade-left' data-aos-delay='0' data-aos-duration='750'>
                                    Detail
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-3 lg:gap-20 gap-8 lg:px-32 font-poppins lg:py-20 py-8'>
                        <div className='bg-white shadow-lg rounded-xl'>
                            <img className=' bg-cover' src={influencerExample} />
                            <div className='px-4'>

                                <h3 className='font-bold py-2'>Garcia Salsabila</h3>

                                <div className='grid grid-cols-2 gap-2'>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Entertaiment</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Actor</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Content Creator</p>
                                </div>

                                <div className='flex justify-between items-center py-2'>
                                    <p className='text-slate-500 text-sm'>Influencer Channel</p>
                                    <div className='bg-slate-200 rounded-full p-2'>
                                        <img className='w-5' src={instagramOutline} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='bg-white shadow-lg rounded-xl'>
                            <img className=' bg-cover' src={influencerExample} />
                            <div className='px-4'>

                                <h3 className='font-bold py-2'>Garcia Salsabila</h3>
                                <div className='grid grid-cols-2 gap-2'>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Entertaiment</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Actor</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Content Creator</p>
                                </div>
                                <div className='flex justify-between items-center py-2'>
                                    <p className='text-slate-500 text-sm'>Influencer Channel</p>
                                    <div className='bg-slate-200 rounded-full p-2'>
                                        <img className='w-5' src={instagramOutline} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='bg-white shadow-lg rounded-xl'>
                            <img className=' bg-cover' src={influencerExample} />
                            <div className='px-4'>

                                <h3 className='font-bold py-2'>Garcia Salsabila</h3>
                                <div className='grid grid-cols-2 gap-2'>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Entertaiment</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Actor</p>
                                    <p className='text-[0.7rem] border text-center rounded-xl border-gray-900'>Content Creator</p>
                                </div>

                                <div className='flex justify-between items-center py-2'>
                                    <p className='text-slate-500 text-sm'>Influencer Channel</p>
                                    <div className='bg-slate-200 rounded-full p-2'>
                                        <img className='w-5' src={instagramOutline} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <button className=" bg-transparent from-[#7D80E0] to-[#C885DE] border-2 border-[#AA83DF] text-[#AA83DF] shadow-lg px-4 py-2 mb-8 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl font-poppins font-semibold w-[18rem]">
                        Influencer lainnya
                    </button>

                </div>
            </div>

            {/* TESTIMONIAL */}
            <div id='kata-mereka' className="bg-[#F4F8FB py-10">
                <div className='lg:mx-36 mx-8 lg:pb-12 flex flex-col items-center'>
                    <h2 className='font-poppins text-3xl text-center font-bold py-4'>Apa kata mereka?</h2>
                    <TestimonialSlider />
                </div>
            </div>

            {/* PARTNER KAMI */}
            <Partner />

            {/* FAQ */}
            <div id='faq' className='bg-[#F4F8FB]'>
                <div className='py-12 lg:mx-36 mx-8 font-poppins'>
                    <h2 className='text-3xl font-semibold pb-2'>FAQ</h2>
                    <p className=' text-[#64748B] pb-8'>Berikut adalah beberapa pertanyaan yang paling sering ditanyakan.</p>
                    <div className='grid lg:grid-cols-2'>
                        <div className='flex mb-8' data-aos='fade-right' data-aos-delay='0' data-aos-duration='750'>
                            <div className='mr-4'>
                                <div className=' bg-[#FFE152] h-10 w-10 rounded-full flex justify-center items-center text-2xl'>
                                    <p>?</p>
                                </div>
                            </div>
                            <div>
                                <h3>Sistem kerjanya gimana ya?</h3>
                                <p className='text-[#64748B]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            </div>
                        </div>
                        <div className='flex mb-8' data-aos='fade-right' data-aos-delay='200' data-aos-duration='750'>
                            <div className='mr-4'>
                                <div className=' bg-[#FFE152] h-10 w-10 rounded-full flex justify-center items-center text-2xl'>
                                    <p>?</p>
                                </div>
                            </div>
                            <div>
                                <h3>Sistem kerjanya gimana ya?</h3>
                                <p className='text-[#64748B]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            </div>
                        </div>
                        <div className='flex mb-8' data-aos='fade-right' data-aos-delay='300' data-aos-duration='750'>
                            <div className='mr-4'>
                                <div className=' bg-[#FFE152] h-10 w-10 rounded-full flex justify-center items-center text-2xl'>
                                    <p>?</p>
                                </div>
                            </div>
                            <div>
                                <h3>Sistem kerjanya gimana ya?</h3>
                                <p className='text-[#64748B]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            </div>
                        </div>
                        <div className='flex mb-8' data-aos='fade-right' data-aos-delay='400' data-aos-duration='750'>
                            <div className='mr-4'>
                                <div className=' bg-[#FFE152] h-10 w-10 rounded-full flex justify-center items-center text-2xl'>
                                    <p>?</p>
                                </div>
                            </div>
                            <div>
                                <h3>Sistem kerjanya gimana ya?</h3>
                                <p className='text-[#64748B]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div id='contact' className='bg-[#F4F8FB]'>
                <div className='py-12 lg:mx-36 mx-8 font-poppins'>
                    <h2 className='text-3xl font-semibold pb-2'>Kontak kami</h2>
                    <p className='text-[#64748B]'>Hubungi kami, jika butuh bantuan lebih lanjut.</p>
                    <div className='flex my-8 gap-12 justify-between lg:flex-row flex-col'>
                        <div className='relative lg:w-[35%] h-[32rem] bg-gradient-to-b from-[#9900EF] to-[#0693E3] rounded-lg overflow-hidden' data-aos="flip-right" data-aos-duration='1000'>
                            <div className='text-white lg:m-8 m-4 text-sm font-light'>
                                <div className='flex items-center pb-6 gap-4'>
                                    <div className='w-8'>
                                        <img src={waLogo} className='w-[100%]' />
                                    </div>
                                    <div className='w-[80%]'>
                                        <a href='wa.me/6281234567890'>+62 812-3456-7890</a>
                                    </div>
                                </div>
                                <div className='flex items-center pb-6 gap-4'>
                                    <div className='w-8'>
                                        <img src={mailLogo} className='w-[100%]' />
                                    </div>
                                    <div className='w-[80%]'>
                                        <a href='mailto:partnership@youtzmedia.id'>partnership@youtzmedia.id</a>
                                    </div>
                                </div>
                                <div className='flex items-start gap-4'>
                                    <div className='w-8'>
                                        <img src={mapLogo} className='w-[100%]' />
                                    </div>
                                    <div className='w-[80%]'>
                                        <p className='pb-2'>Youtz Media Office</p>
                                        <p>Jl. Cendrawasih VI, RT.004/RW.003, Sawah Baru, Kec. Ciputat, Kota Tangerang Selatan, Banten 15413</p>
                                    </div>
                                </div>

                            </div>
                            <div className='absolute bottom-12 lg:left-12 left-4'>
                                <div className='flex gap-4 justify-start items-center'>
                                    <div><img src={igLogo} /></div>
                                    <div><img src={ytLogo} /></div>
                                    <div><img src={linkLogo} /></div>
                                    <div><img src={tiktokLogo} /></div>
                                </div>
                            </div>
                            <div className='absolute bg-[#FFE152] h-60 w-60 rounded-full lg:-right-16 -right-20 -bottom-14'>
                            </div>
                            <div className='absolute bg-[#C2E9FF] h-28 w-28 rounded-full right-20 bottom-24'>
                            </div>
                        </div>
                        <div className='lg:w-[60%]'>
                            <h2 className='text-2xl font-semibold pb-2'>Ada pertanyaan?</h2>
                            <p className='text-[#64748B]'>Isi form dibawah ini, untuk mengajukan pertanyaan baru.</p>
                            <form className='my-4'>
                                <div className='pb-4'>
                                    <label htmlFor='name' className='text-[#64748B] text-sm'>Nama</label><br />
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className=' bg-transparent border-b-2 w-full font-poppins text-[#64748B] focus:outline-none h-8'
                                        placeholder='John Doe'
                                    // value={yourName}
                                    // onChange={handleChange}
                                    />
                                </div>
                                <div className='pb-4'>
                                    <label htmlFor='email' className='text-[#64748B] text-sm'>Email</label><br />
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className=' bg-transparent border-b-2 w-full font-poppins text-[#64748B] focus:outline-none h-8'
                                        placeholder='johndoe@gmail.com'
                                    // value={yourName}
                                    // onChange={handleChange}
                                    />
                                </div>
                                <div className='pb-4'>
                                    <label htmlFor='message' className='text-[#64748B] text-sm'>Pesan</label><br />
                                    <textarea
                                        id="message"
                                        name="message"
                                        className=' bg-transparent border-b-2 w-full font-poppins text-[#64748B] focus:outline-none h-36'
                                        placeholder='Ketik pesan Anda disini...'
                                    // value={yourName}
                                    // onChange={handleChange}
                                    ></textarea>
                                </div>
                                <button className='bg-gradient-to-b from-[#9900EF] to-[#0693E3] text-white py-3 px-16 rounded-full text-sm'>Kirim pertanyaan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>




            <Footer />
        </>
    );
};

export default Home;
