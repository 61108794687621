import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import youtzMediaLogo from '../../assets/images/youtzmedia.png'

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Navbar = () => {
    const [showTools, setShowTools] = useState(false);
    const toggleToolsMobile = () => {
        setShowTools(!showTools);
    };


    const [scrolled, setScrolled] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isToolsOpen, setIsToolsOpen] = useState(false);
    const toggleTools = () => {
        setIsToolsOpen(!isToolsOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setIsAtTop(currentScrollPos === 0);


            if (isAtTop || currentScrollPos < prevScrollPos) {
                setScrolled(false);
            } else {
                setScrolled(true);
            }
            setPrevScrollPos(currentScrollPos);
            setIsToolsOpen(false);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const navbarClassName = `fixed w-full z-[999] transition-all duration-500 transform ${isAtTop ? 'bg-transparent' : 'bg-gradient-to-r from-[#FF5FF9] to-[#839BF1]'} ${scrolled ? '-translate-y-full' : 'translate-y-0'}`;

    return (
        <>
            <div id='navbar' className={navbarClassName}>
                <div className='flex lg:mx-32 mx-8 justify-between items-center py-4'>
                    <div className='w-16'>
                        <img src={youtzMediaLogo} className='w-full' />
                    </div>
                    <ul className={`text-white font-montserrat lg:flex gap-12 font-semibold hidden`}>
                        <li className='hover:text-[#4F4DCA]'><Link to='/'>Home</Link></li>
                        <li className='hover:text-[#4F4DCA]'><Link to='/influencer'>Influencer</Link></li>
                        <li className='hover:text-[#4F4DCA]'><Link to='/faq'>FAQ</Link></li>
                        <li className='hover:text-[#4F4DCA] relative'>
                            <button onClick={toggleTools} className='focus:outline-none'>Tools</button>
                            {isToolsOpen && (
                                <ul className='absolute top-full w-[22rem] -left-36 bg-white text-black text-base mt-2 rounded-lg shadow-lg'>
                                    <li className='px-5 py-2 hover:text-white text-sm hover:rounded-t-lg hover:bg-gradient-to-b from-[#BA57F1] to-[#67BEEF]'><Link to='/tools/calculator-eng-rate-instagram'>Kalkulator engagement rate Instagram</Link></li>
                                    <li className='px-5 py-2 hover:text-white text-sm hover:rounded-b-lg hover:bg-gradient-to-b from-[#BA57F1] to-[#67BEEF]'><Link to='/tools/calculator-eng-rate-tiktok'>Kalkulator engagement rate Tiktok</Link></li>
                                </ul>
                            )}
                        </li>
                        <li className='hover:text-[#4F4DCA]'><Link to='/profile'>Profile</Link></li>
                        <li className='hover:text-[#4F4DCA]'><Link to='/testimoni'>Testimoni</Link></li>
                    </ul>
                    <div className="block lg:hidden" onClick={toggleMenu}>
                        <svg
                            className="h-10 w-10 text-white cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </div>
                </div>
                <div className={`absolute h-screen w-[75%] pl-6 pr-8 pt-8 duration-300 bg-white ${isMenuOpen ? 'right-0' : '-right-[100%]'}`}>
                    <ul className={`text-gray-500 text-sm font-montserrat flex flex-col font-medium`}>
                        <Link to='/'><li>Home</li></Link>
                        <hr className='my-3 border-purple-200' />
                        <Link to='/influencer'><li>Influencer</li></Link>
                        <hr className='my-3 border-purple-200' />
                        <Link to='/faq'><li>FAQ</li></Link>
                        <hr className='my-3 border-purple-200' />
                        <div>
                            <li className='mb-2 flex justify-between items-center cursor-pointer' onClick={toggleToolsMobile}>
                                Tools
                                {showTools ? <FaChevronUp /> : <FaChevronDown />}
                            </li>
                            {showTools && (
                                <li>
                                    <ul className='text-xs list-item pl-4 list-none'>
                                        <Link to='/tools/calculator-eng-rate-instagram'><li className='mb-2'>Kalkulator engagement rate Instagram</li></Link>
                                        <Link to='/tools/calculator-eng-rate-tiktok'><li>Kalkulator engagement rate Tiktok</li></Link>
                                    </ul>
                                </li>
                            )}
                        </div>
                        <hr className='my-3 border-purple-200' />
                        <Link to='/profile'><li>Profile</li></Link>
                        <hr className='my-3 border-purple-200' />
                        <Link to='/testimoni'><li>Testimoni</li></Link>
                    </ul>
                </div>
            </div>
        </>

    )
}

export default Navbar;