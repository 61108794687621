// TestimonialSlider.js
import React from 'react';
import Slider from 'react-slick';

const testimonials = [
    { name: "Nada Rahmawida", testimonial: "Morbi lacinia ultricies sapien vitae condimentum. Mauris dictum massa nec sem tristique pharetra. Nullam mollis fringilla metus eget congue. Fusce nec hendrerit lorem. Vestibulum nisl orci,", loc: "DKI Jakarta   " },
    { name: "Nada Rahmawida", testimonial: "Morbi lacinia ultricies sapien vitae condimentum. Mauris dictum massa nec sem tristique pharetra. Nullam mollis fringilla metus eget congue. Fusce nec hendrerit lorem. Vestibulum nisl orci,", loc: "DKI Jakarta   " },
    { name: "Nada Rahmawida", testimonial: "Morbi lacinia ultricies sapien vitae condimentum. Mauris dictum massa nec sem tristique pharetra. Nullam mollis fringilla metus eget congue. Fusce nec hendrerit lorem. Vestibulum nisl orci,", loc: "DKI Jakarta   " },
    // Tambahkan lebih banyak testimoni di sini
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Jumlah testimoni yang ditampilkan sekaligus
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const TestimonialSlider = () => (
    <div className="mx-auto w-full py-6 font-poppins">
        <Slider {...settings}>
            {testimonials.map((item, index) => (
                <div key={index} className="px-2 py-4">
                    <div className="bg-white pt-6 px-6 rounded-lg shadow-lg text-center border-2 border-[#2970E6]">
                        <p className='text-[#0062D6]'>{item.loc}</p>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">{item.name}</h3>
                        <p className="text-gray-700 mb-4 text-sm">"{item.testimonial}"</p>
                        <p className='text-[2rem]'>"</p>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
);

export default TestimonialSlider;
