// TestimonialSlider.js
import React from 'react';
import Slider from 'react-slick';

const testimonials = [
    { name: "Ibnu Budiargo", testimonial: "Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik. Kolaborasi ini benar-benar memungkinkan saya untuk mengeksplorasi dan menghasilkan konten yang menginspirasi.", title: "UI/UX Designer" },
    { name: "Aziz Rahmad", testimonial: "Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik. Kolaborasi ini benar-benar memungkinkan saya untuk mengeksplorasi dan menghasilkan konten yang menginspirasi.", title: "Frontend Developer" },
    { name: "Azhar", testimonial: "Sebagai influencer, bekerja dengan Youtz Media adalah impian yang menjadi kenyataan. Mereka tidak hanya memberikan kebebasan kreatif tapi juga mendukung ide-ide unik. Kolaborasi ini benar-benar memungkinkan saya untuk mengeksplorasi dan menghasilkan konten yang menginspirasi.", title: "UI/UX Designer" },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const TestimonialProfileSlider = () => (
    <div className="mx-auto py-6 font-poppins">
        <Slider {...settings}>
            {testimonials.map((item, index) => (
                <div key={index} className="px-2 py-4">
                    <div className="bg-gradient-to-br from-[#9900EF] to-[#0693E3] lg:py-16 py-8 lg:px-20 px-10 shadow-lg">
                        <p className="text-white mb-8 lg:text-2xl text-lg">{item.testimonial}</p>

                        <div className='flex'>
                            <div className='bg-[#FFFFFFdd] w-16 h-16 rounded-full mr-4'></div>
                            <div>
                                <h3 className="text-xl font-bold text-[#FFFFFFaa] mb-2">{item.name}</h3>
                                <p className='text-lg font-semibold text-[#FFFFFFaa] mb-2'>{item.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
);

export default TestimonialProfileSlider;
