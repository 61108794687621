import React from "react";

import bniLogo from "../../assets/images/partner/bni.png"
import dermisLogo from "../../assets/images/partner/dermis.png"
import dmLogo from "../../assets/images/partner/dm.png"
import garnierLogo from "../../assets/images/partner/garnier.png"
import halodocLogo from "../../assets/images/partner/halodoc.png"
import hidrococoLogo from "../../assets/images/partner/hidrococo.png"
import pegadaianLogo from "../../assets/images/partner/pegadaian.png"
import smartfrenLogo from "../../assets/images/partner/smartfren.png"

const Partner = () => {
    return (
        <>
            <div id='partner' className="py-12 lg:mx-36 mx-8">
                <h2 className='font-poppins text-3xl text-center font-bold pt-4 pb-12'>Partner Kami</h2>
                <div className="flex lg:px-24 flex-wrap lg:gap-12 gap-4 items-start justify-center">
                    <div>
                        <img src={bniLogo} />
                    </div>
                    <div>
                        <img src={dmLogo} />
                    </div>
                    <div>
                        <img src={garnierLogo} />
                    </div>
                    <div>
                        <img src={pegadaianLogo} />
                    </div>
                    <div>
                        <img src={halodocLogo} />
                    </div>
                    <div>
                        <img src={smartfrenLogo} />
                    </div>
                    <div>
                        <img src={hidrococoLogo} />
                    </div>
                    <div>
                        <img src={dermisLogo} />
                    </div>
                </div>

            </div>

        </>
    )
}

export default Partner;