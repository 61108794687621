import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar/navbar2';
import Footer from '../components/footer';

import headerImage from '../assets/images/faq/header.png';
import svg from '../assets/images/faq/svg.png';

const Faq = () => {
    const [selectedSection, setSelectedSection] = useState('influencer');

    return (
        <>
            <Navbar />
            <div id="home" className="h-screen -z-20 bg-cover relative" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: `center` }}>
                <div className=' h-full flex justify-center items-center'>
                    <div className="mx-8 lg:mx-80 text-white mt-4">
                        <h1 className=" lg:text-center text-3xl lg:text-5xl font-bold font-poppins my-8 ">FAQ</h1>
                    </div>
                </div>
            </div>
            <div className='relative lg:h-[28rem] h-[18rem]'>
                <div className="absolute -z-20 -top-16 right-0 left-0">
                    <img src={svg} className='w-full' />
                </div>
                <div className='absolute w-full bottom-0'>
                    <div className='flex justify-center lg:gap-20 gap-8 z-10 lg:mx-36 mx-8 py-12'>
                        <button id='influencer' className='w-[22rem] bg-[#9900EF] lg:px-8 px-2 lg:py-6 py-4 lg:rounded-[2rem] rounded-[1rem] border-4 border-[#9900EF] lg:hover:bg-white lg:hover:text-[#000000aa] text-white transition duration-150' onClick={() => setSelectedSection('influencer')}>
                            <p className='lg:text-2xl text-base font-semibold font-poppins'>untuk Influencer</p>
                        </button>
                        <button id='brand' className='w-[22rem] bg-white lg:px-7 px-2 lg:py-6 py-4 lg:rounded-[2rem] rounded-[1rem] border-4 border-purple-500 lg:hover:bg-[#9900EF] lg:hover:text-white text-[#000000aa] transition duration-150' onClick={() => setSelectedSection('brand')}>
                            <p className='lg:text-2xl text-base font-semibold font-poppins'>untuk pemilik Brand</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className='w-full min-h-screen'>
                {selectedSection === 'influencer' && (
                    <div className='section-content flex'>
                        <div className='flex flex-col justify-center items-start'>
                            <button>
                                <p>Youtz Marketplace</p>
                            </button>
                            <button>
                                <p>Youtz Affiliate</p>
                            </button>
                        </div>
                        <div>
                        </div>
                    </div>
                )}

                
                {selectedSection === 'brand' && (
                    <div className='section-content'>
                        {/* Konten untuk Brand */}
                        <h2 className='text-3xl font-bold my-8'>Konten untuk pemilik Brand</h2>
                        <p>Ini adalah konten yang ditujukan untuk pemilik brand...</p>
                    </div>
                )}
            </div>
            <Footer />

        </>
    );
}

export default Faq;