import React from "react";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import campaignCtaImage from '../../assets/images/cta/campaign.png'
import brandCtaImage from '../../assets/images/cta/brand.png'
import influencerCtaImage from '../../assets/images/cta/influencer.png'

const Cta = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 50% of the component is in view
    });

    return (
        <>
            <div id='cta' className='h-96 lg:h-80 relative'>
                <div id='cta' className='w-[85%] lg:w-[80%] bg-gradient-to-br from-[#B5BAE8] to-[#CF6FD1] absolute  -top-12 left-1/2 -translate-x-1/2 rounded-3xl py-10 lg:py-6'>
                    <div className='p-4 flex flex-col md:flex-row justify-evenly items-center'>
                        <div ref={ref}>
                            <div className='bg-[#CA9BE0] p-4 rounded-full w-28 h-28 flex'>
                                <img src={influencerCtaImage} />
                            </div>
                            <div className='text-white text-center'>
                            <p className='text-4xl font-bold'>
                                {inView ? <CountUp end={99000} duration={3} /> : '0'}+
                            </p>
                                <p className='text-xl mt-2 font-medium'>Influencer</p>
                            </div>
                        </div>
                        <div className="my-12">
                            <div className='bg-[#CA9BE0] p-4 rounded-full w-28 h-28 flex'>
                                <img src={brandCtaImage} />
                            </div>
                            <div className='text-white text-center'>
                                <p className=' text-4xl font-bold'>0+</p>
                                <p className='text-xl mt-2 font-medium'>Brand</p>
                            </div>
                        </div>
                        <div>
                            <div className='bg-[#CA9BE0] p-4 rounded-full w-28 h-28 flex'>
                                <img src={campaignCtaImage} />
                            </div>
                            <div className='text-white text-center'>
                                <p className=' text-4xl font-bold'>0+</p>
                                <p className='text-xl  mt-2 font-medium'>Campaign Post</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Cta;