import React from 'react';

const Contact = () => {
    return (
        <>
        
        
        </>
    );
};

export default Contact;