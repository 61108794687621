import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer';


import headerImage from '../assets/images/about.png'
import healthIcon from '../assets/images/influencer/dumbbell.png'
import techIcon from '../assets/images/influencer/android.png'
import lifesyleIcon from '../assets/images/influencer/needs.png'
import entertainIcon from '../assets/images/influencer/film-reel.png'
import gamingIcon from '../assets/images/influencer/game-controller.png'
import foodIcon from '../assets/images/influencer/sausage.png'
import fashionIcon from '../assets/images/influencer/tshirt.png'
import nanditaImg from '../assets/images/influencer/nandita.png'
import instagramLogo from '../assets/images/logo/instagram_outline.png'

const Influencer = () => {
    return (
        <>
            <Navbar />
            <div id="home" className="h-screen bg-cover relative" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: `center` }}>
                <div className='bg-gradient-to-tr from-[#CD5AE0bb] via-[#A478C788] to-[#446DD4bb] h-full flex justify-center items-center'>
                    <div className="mx-8 lg:mx-80 text-white mt-4">
                        <h1 className=" lg:text-center text-3xl lg:text-5xl font-bold font-poppins my-8 ">Influencer Youtz</h1>
                        <p className="text-base lg:text-xl lg:text-center font-montserrat">Daftar Influencer dari Youtz dengan berbagai macam kebutuhan.</p>
                    </div>
                </div>
            </div>

            {/* KATEGORI INFLUENCER */}
            <div id='category' className='w-full py-12'>
                <div className='lg:mx-48 mx-8'>
                    <h2 className='text-xl lg:text-2xl text-center font-poppins font-bold lg:mb-8 mb-2'>Kategori Influencer</h2>
                    <div className='grid grid-cols-2 lg:grid-cols-4 items-center align-middle justify-center gap-4 lg:mx-36 mb-8 mt-4'>
                        <div className='flex flex-col items-center'>
                            <img src={healthIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Health & Sport</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={techIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Technology</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={lifesyleIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Lifestyle & Travel</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={gamingIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Gaming</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 lg:grid-cols-3 items-center gap-4 lg:mx-60'>
                        <div className='flex flex-col items-center'>
                            <img src={foodIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Food</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={fashionIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Fashion & style</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={entertainIcon} width={50} alt='' />
                            <p className='font-poppins text-center text-[#0E0101]'>Entertaiment</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* INFLUENCER TERFAVORIT */}
            <div id='favorite' className='relative w-full py-12'>
                <div className='lg:mx-48 mx-8'>
                    <h2 className='text-xl lg:text-2xl text-center font-poppins font-bold lg:mb-8 mb-2'>Influencer Terfavorit</h2>
                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-8 mt-8'>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>
                        <div className='shadow-xl rounded-3xl flex flex-col items-center px-6 py-8'>
                            <img src={nanditaImg} width={150} alt=''/>
                            <h3 className='text-xl lg:text-2xl text-center font-poppins font-bold'>Nandita Firli</h3>
                            <p className='text-base text-center font-poppins'>Jakarta | wanita</p>
                            <div className='flex flex-wrap gap-2 mb-4'>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Entertaiment</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Actor</p>
                                </div>
                                <div className='border border-spacing-2 px-2 rounded-full border-black'>
                                    <p className='text-sm text-center font-poppins'>Content Creator</p>
                                </div>
                            </div>
                            <p className='text-sm text-center font-poppins'>Influencer channel</p>
                            <img src={instagramLogo} width={20} alt=''/>
                        </div>  
                    </div>
                    <div className='flex justify-center mt-8'>
                        <button className='border border-gray-600 rounded-full px-6 py-2'>
                        <p className='text-lg text-center font-poppins font-medium text-gray-600'>Lihat semua</p>
                        </button>
                    </div>
                </div>
            </div>


            {/* TENTANG */}
            <div id='about' className='relative w-full py-12 mb-20'>
                <div className='lg:mx-48 mx-8'>
                    <h2 className='text-xl lg:text-2xl text-center font-poppins font-bold lg:mb-8 mb-2'>Tentang Influencer Marketing</h2>
                    <div className='lg:flex justify-between lg:mb-8 mb-4'>
                        <div className='lg:w-[50%] mb-4 lg:mb-0'>
                            <h3 className='text-lg font-poppins font-bold mb-2'>Tentang Influencer Marketing</h3>
                            <p className='text-base font-poppins'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus. </p>
                        </div>
                        <div className='lg:w-[40%]'>
                            <h3 className='text-lg font-poppins font-bold mb-2'>KOL Strategy</h3>
                            <p className='text-base font-poppins'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus.</p>
                        </div>
                    </div>
                    <div className='lg:flex justify-between'>
                        <div className='lg:w-[50%] mb-4 lg:mb-0'>
                            <h3 className='text-lg font-poppins font-bold mb-2'>Daftar Influencer Marketing</h3>
                            <p className='text-base font-poppins'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet neque non nibh porta venenatis. Morbi faucibus, tortor eu posuere consequat, neque nibh consectetur purus, a condimentum eros quam eu neque. Nam consectetur in dui at porttitor.</p>
                        </div>
                        <div className='lg:w-[40%]'>
                            <h3 className='text-lg font-poppins font-bold mb-2'>Influencer tersebar di seluruh Indonesia</h3>
                            <p className='text-base font-poppins'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nisi a sagittis commodo. Nulla in arcu sollicitudin, iaculis quam et, pellentesque urna. Aliquam et nisl vitae lorem cursus auctor id fermentum lectus.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />


        </>
    )
};

export default Influencer;