import React from 'react';
import youtzMediaLogo from '../assets/images/youtzmedia.png'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
        <div id='footer' className='bg-gradient-to-tr from-[#9B4BB7] to-[#3E87C3] text-white font-montserrat'>
            <div className='lg:mx-36 mx-8 py-12'>
                <div className='flex justify-between flex-col lg:flex-row items-start'>
                    <div className='lg:w-[30%]'>
                        <img src={youtzMediaLogo} className='lg:w-24 w-32'/>
                    </div>
                    <div className='flex flex-col lg:w-[25%] mt-6 lg:mt-0'>
                        <Link className='font-medium mb-2'>COMPANY</Link>
                        <Link className='opacity-75'>Influencer</Link>
                        <Link className='opacity-75'>Jasa website</Link>
                        <Link className='opacity-75'>Handling social media</Link>
                        <Link className='opacity-75'>Production house</Link>
                        <Link className='opacity-75'>Event organizer</Link>
                    </div>
                    <div className='flex flex-col lg:w-[20%] mt-6 lg:mt-0'>
                        <Link className='font-medium mb-2'>SUPPORT</Link>
                        <Link className='opacity-75'>FAQ</Link>
                        <Link className='opacity-75'>Kontak</Link>
                    </div>
                    <div className='lg:w-[25%] mt-6 lg:mt-0'>
                        <p className='font-medium mb-2'>Location</p>
                        <p className='opacity-75'>Jl. Cendrawasih VI, RT.004/RW.003, Sawah Baru, Kec. Ciputat, Kota Tangerang Selatan, Banten 15413</p>
                    </div>
                </div>
                <hr className='my-6'/>
                <p>Copyright 2024 Youtz Media</p>
            </div>
        </div>
        </>
    );
};

export default Footer;